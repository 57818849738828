@use "builder/index"
@use "generator"

.gephart
  &-menu
    &-logo

      border-radius: 0.5rem
      padding: 0.5rem 1rem
      margin-bottom: 1rem
