
.tracker
    &__select-wrapper
        left: -225px
        top: 45px

    &__select
        width: 300px !important

    &__timer
        min-width: 100px
        width: 100%
