/*@use "bootstrap/index.css"*/
@use "gephart/index.sass"
@use "reactModal.css"
@use "reactTabs.css"
@use "components/tracker"

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap')

html
  font-size: 14px

body
  font-family: 'Poppins', sans-serif

  @media (min-width: 768px)
    .sidebar
      max-width: 230px

  @media (min-width: 992px)
    .content
      margin-left: calc(230px + .5rem)

.cursor-pointer
  cursor: pointer

.disk-space
  opacity: .5
  transition: opacity .2s

  &:hover
    opacity: 1

.list-group-item--done
  &:before
    border-color: #1f2937!important

  &:after
    border-color: #1f2937!important
    background: #1f2937!important

.list-group-item--stripe
  text-decoration: line-through

.sidebar-inner
  min-height: 100vh

.user-menu
  min-height: calc(100vh - 1rem)

.timetracker-button_wrap
  position: relative

.timetracker-button_list
  position: absolute
  z-index: 1040
  right: 0px
  width: 300px

.checklist-item
  transition: opacity .2s
  position: relative

  &:before, &:after
    content: ""
    position: absolute
    height: 2px
    left: 0
    width: 100%
    background: #eee
    border-radius: 2px
    opacity: 0

  &:before
    top: -2px

  &:after
    bottom: -2px

  &.is-overTop
    &:before
      opacity: 1

  &.is-overDown
    &:after
      opacity: 1

  &.is-done
    opacity: .3

    label
      text-decoration: line-through

  &.dragging
    opacity: .5


@media (max-width: 767px)
  .gephart-menu-logo
    max-width: 200px
    margin: 2rem auto 0

  .user-menu
    min-height: 0

  .navbar-breadcrumb, #navbarSupportedContent
    display: none!important

  .timetracker-button_wrap
    position: static

  .timetracker-button_list
    width: calc(100% - 2rem)
    right: 1rem

  .timetracker-button_desc
    display: none

    + .btn
      color: #10b981


.card-item-wrap
  overflow: visible

  &:before
    position: absolute
    content: ""
    top: -0.48rem
    left: 0
    width: 100%
    height: 0.24rem
    background: black
    border-radius: 1rem
    opacity: .4
    transition: all .2s
    opacity: 0

  &:after
    position: absolute
    content: ""
    bottom: -0.48rem
    left: 0
    width: 100%
    height: 0.24rem
    background: black
    border-radius: 1rem
    opacity: .4
    transition: all .2s
    opacity: 0

.card-item-wrap-overTop
  z-index: 999
  &:before
    opacity: .4

.card-item-wrap-overDown:after
  opacity: .4

.card-item-wrap-overDown + .card-item-wrap-overTop:before
  opacity: 0!important

.timetrack-table
  &__item
    border-radius: .5rem
    padding: 0!important
    font-weight: 400
    font-size: 1rem
    cursor: pointer
    transition: none

    &:hover
      max-height: calc(60*24px)!important
      z-index: 9

    &__content
      padding: .5rem

.table tr:has(+ tr.thead-light) td
  border-bottom: 0

.react-select-container
  color: #6b7280
  font-size: .875rem
  .react-select__single-value
    color: #6b7280

  .react-select__control
    min-height: 34px
    border: .0625rem solid #d1d5db
    border-radius: .5rem
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .07)

  .react-select__indicator
    padding: 6px

.react-tabs
  &__tab
    border: 0
    bottom: 0
    outline: none

    &:focus:after
      display: none

    &--selected
      background: rgba(17,24,39,.125)
      border-color: rgba(17,24,39,.125)
      color: #374151

    &-list
      margin-bottom: 0
      border-bottom: 0

    &-panel
      border: .0625rem solid rgba(17,24,39,.125)
      padding: 1.25rem 1.5rem
      border-radius: 0 .5rem .5rem .5rem
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .07)